<template>
   <article>
			<div class="container">
				<h1>КОНТАКТЫ</h1>
				<div class="items_container">
				<div>
					<fa icon="house"/>
					<h4>Адрес самовывоза</h4>
					<p>ул. Асаналиева, д.58</p>
					<p>Самовывоз по предварительному заказу</p>
				</div>
				<div>
					<fa icon="phone"/>
					<h4>Телефон для связи</h4>
					<p><a href="tel:+375296565372">+375296565372</a></p>
				</div>
				<div>
					<fa icon="envelope-circle-check" />
					<h4>Электронная почта</h4>
					<p>ch.v@bk.ru</p>
				</div>
				</div>
			</div>
			<hr>
			<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2353.568288358369!2d27.540109377323773!3d53.85054197243985!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46dbd1ad54c8749b%3A0xe8d0677c8ab59128!2z0YPQuy4g0JDRgdCw0L3QsNC70LjQtdCy0LAgNTgsINCc0LjQvdGB0Lo!5e0!3m2!1sru!2sby!4v1682281706013!5m2!1sru!2sby" width="800" height="600" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
			<hr>
		</article>
</template>

<script>

</script>

<style>
.items_container {
	font-size: 1.8em;
}
.items_container div {
	text-align: center;
}
.items_container div p a {
	text-decoration: none;
	color: black;
}
.container {
	max-width: 100%;
	width: 80%;
	height: auto;
	background: linear-gradient(to right, rgba(248, 248, 255, .5), rgba(255, 182, 193, .5));
	margin: 50px auto;
	padding-top: 50px;
	padding-bottom: 50px;
	box-shadow: 0px 0px 5px black;
	border-radius: 15px;
}

.container h1 {
	text-align: center;
	font-size: 2.5em;
	text-transform: uppercase;
}
</style>