<template>
  <div>
		<div class="my_header">

		<div class="logotype">
			<img src="../assets/new_logo_without.jpg" alt="logo">
			<h1 class="new_font">мастерская воздушного декора</h1>
		</div>
		
		<nav >
		<ul class="navigation">
		    <li><router-link to="/">Главная</router-link></li>
		    <li><router-link to="/catalog">Каталог</router-link></li>
		    <li><router-link to="/study">Обучение</router-link></li>
		    <li><router-link to="/delivery">Доставка и оплата</router-link></li>
		    <li><router-link to="/contacts">Контакты</router-link></li>
			<li><router-link to="/cart"><fa icon="cart-shopping"/> {{ cartCount }}</router-link></li>
		</ul>
		</nav>
		</div>

  </div>
</template>

<script>
import store from "../storage/index.js"

export default {
    name: 'NavBar',
	computed: {
		cartCount() {
			return this.$store.state.cartItems.length
		}
	}
}
</script>

<style>
    @import url('https://fonts.googleapis.com/css2?family=Marck+Script&display=swap');

* {
	padding: 0;
	margin: 0;
	box-sizing: border-box;
	font-family: Lucida Sans Unicode;
}

body {
	background-color: white;
}

/*Header*/
.my_header {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	flex-direction: row;
	align-items: center;
	padding: 10px 8%;
	background-color: white;
}

/*The logotype*/
.logotype {
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-direction: column;
	flex-wrap: wrap;
}

.logotype img {
	height: 250px;
	width: 350px;
}

.new_font {
	margin-top: -40px;
	font-family: 'Marck Script', cursive;
	color: dimgrey;
	font-size: 42px;
	text-shadow: 5px 0px 5px grey;
	text-shadow: 10px 0px 10px lightpink;
}

/*The Navigation panel*/
.navigation {
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-wrap: wrap;
	list-style: none;
}

.navigation li {
	margin: 0px 15px;
}

.navigation li a {
	display: inline-block;
	text-decoration: none;
	color: black;
	background-color: lightpink;
	border-radius: 30px;
	padding: 15px;
	transition: 0.2s;
	font-size: 1.3em;
}

.navigation li a:hover {
	background-color: black;
	color: white;
}


</style>