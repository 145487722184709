<template>
  <div>
		<div class="contacts">

			<!-- Social media icons -->
			<div class="social">
			<a href="https://www.instagram.com/myballoons.by/"><i class="fa-brands fa-instagram"></i></a>
			<a href="https://www.pinterest.com/myballoonsby/"><i class="fa-brands fa-pinterest"></i></a>
			<a href="viber://chat?number=%2B375259121757"><i class="fa-brands fa-viber"></i></a>
			<a href="https://t.me/myballoonsby"><i class="fa-brands fa-telegram"></i></a>
			<a href="https://wa.me/375259121757"><i class="fa-brands fa-whatsapp"></i></a>
			</div>

			<!-- Contacts -->
			<h2>Связь с нами</h2>

			<div class="contacts_container">
			<div class="contact_item">
				<i class="fa-solid fa-mobile-screen-button"></i>
				<h3 class="telephone"><a href="tel:+375296565372">+37529-65-65-372</a></h3>
			</div>
			<div class="contact_item">
				<i class="fa-solid fa-envelope"></i>
				<a href="mailto:ch.v@bk.ru">ch.v@bk.ru</a>
			</div>
			<div class="contact_item">
				<i class="fa-solid fa-map-location"></i>
				<h3>г. Минск, ул. Асаналиева, д.58</h3>
			</div>
			</div>
		</div>

		<hr>
		<h4 class="copyright">©2023 myballoons УНП АС0564528 Касперович Т.Г.<br>Представленные на данном сайте работы являются примерами авторского дизайна.</h4>
	</div>
	
</template>
   
<script>
export default {
    name: 'Footer'
}
</script>

<style>
    .social a {
	display: inline-flex;
	justify-content: center;
	align-items: center;
	text-decoration: none;
	width: 90px;
	height: 90px;
	margin: 0 10px;
	font-size: 50px;
	background: white;
	color: black;
	transition: 0.4s;
	border-radius: 30%;
}

.social a:nth-child(1):hover {
	color: #833AB4;
	box-shadow: inset 0px 0px 5px black;
}

.social a:nth-child(2):hover {
	color: #E60023;
	box-shadow: inset 0px 0px 5px black;
}

.social a:nth-child(3):hover {
	color: #7360F2;
	box-shadow: inset 0px 0px 5px black;
}


.social a:nth-child(4):hover {
	color: #27A7E7;
	box-shadow: inset 0px 0px 5px black;
}

.social a:nth-child(5):hover {
	color: #5FFC7B;
	box-shadow: inset 0px 0px 5px black;
}


/*Contacts*/
.contacts {
	text-align: center;
	max-width: 80%;
	margin: 0px auto;
	margin-bottom: 50px;
}

.contacts h2:first-of-type {
	margin-bottom: 50px;
}

.copyright {
	text-align: center;
}

.contacts_container {
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-wrap: wrap;
}

.contact_item {
	width: calc(33% - 20px);
	height: 150px;
	background: dimgrey;
	color: white;
	text-align: center;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	border-radius: 10px;
	transition: 0.5s;
}

.contacts_container div:hover {
	/*background: #48dbfb;*/
	background: black;
}

.contacts_container div i {
	font-size: 40px;
	color: white;
}

.contacts_container div a {
	text-decoration: none;
	color: white;	
	font-size: 18px;
}

/*The map*/
iframe {
	width: 100%;
	border-radius: 5px;
	height: 50vh;
}
</style>