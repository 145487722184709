<template>
  <main>
			<!-- The baneer -->
			<div class="banner">
				<div class="info">
					<h1>Скидка 10 %</h1>
					<h3>Весь июнь на все заказы <br>с фигурами PartyDeco</h3>
					<router-link to="/catalog" class="sale10">Перейти в каталог</router-link>
				</div>
			</div>

			<!-- Special offers -->

			<!-- Special offers/Pictures -->
			<div class="sale_container">
				
				<h2 class="sale_title">Предложения месяца</h2>

				<div class="card_container">
				
				<div class="card">
					<img src="Images/offer1.jpg" alt="offer #1">
					<div class="sale">СКИДКА</div>
					<div class="cardinfo">
						<h2 class="first_price">110 р.</h2>
						<h2>100 р.</h2>
						<p>3 шара хром<br>
						3 шара дабл Стафф<br>
						Олененок<br>
						Цифра 
						</p>
					</div>
				</div>

				<div class="card">
					<img src="Images/offer2.jpg" alt="offer #2">
					<div class="sale">СКИДКА</div>
					<div class="cardinfo">
						<h2 class="first_price">100 р.</h2>
						<h2>90 р.</h2>
						<p>3 шара конфетти<br>
						7 шаров розово/белых<br>
						Большой прозрачный шар с перьями  и надписью
						</p>
					</div>
				</div>

				<div class="card">
					<img src="Images/offer3.jpg" alt="offer #3">
					<div class="sale">СКИДКА</div>
					<div class="cardinfo">
						<h2 class="first_price">210 р.</h2>
						<h2>195 р.</h2>
						<p>Шар гигант с надписью<br>
						4 звезды<br>
						5 конфетти<br>
						7 хром золото<br>
						10 черных 
						</p>
					</div>
				</div>

			</div>

			<!-- Special offers/Videos -->
			<div class="gif_container">

			<div class="gifka1 gifka">
				<video autoplay muted loop>
					<source src="Videos/gifka_first.mp4" type="video/webm">
				</video>
				<div class="sale">СКИДКА</div>
				<div class="gifka_info">
					<h2>150 р.</h2>
					<h2>130 р.</h2>
				</div>
			</div>

			<div class="gifka2 gifka">
				<video autoplay muted loop>
					<source src="Videos/gifka_second.mp4" type="video/webm">
				</video>
				<div class="sale">СКИДКА</div>
				<div class="gifka_info">
					<h2>80 р.</h2>
					<h2>55 р.</h2>
				</div>
			</div>

			<div class="gifka3 gifka">
				<video autoplay muted loop>
					<source src="Videos/gifka_third.mp4" type="video/webm">
				</video>
				<div class="sale">СКИДКА</div>
				<div class="gifka_info">
					<h2>130 р.</h2>
					<h2>115 р.</h2>
				</div>
			</div>

			</div>

		</div>

			<!-- Our advances -->
			<div class="advance">
				<h2>Почему выбирают нас</h2>
				<div class="pros">
					
					<div class="pros1 p1">
						<p><i class="fa-solid fa-earth-americas"></i></p>
						<div>
						<h3>Ответственнность</h3>
						<p>Мы заботимся о здоровье ваших детей и работаем только с качественными материалами. </p>	
						</div>
					</div>

					<div class="pros1 p2">
						<p><i class="fa-brands fa-fly"></i></p>
						<div>
						<h3>Креативность</h3>
						<p>Мы следим за всеми модными трендами и у нас вы найдете самые стильные и современные сочетания и наборы Шариков.</p>
						</div>
					</div>

					<div class="pros1 p3">
						<p><i class="fa-solid fa-screwdriver-wrench"></i></p>
						<div>
						<h3>Качество</h3>
						<p>Гарантия на полет латексных Шаров в зимнее время от 3 суток ,в летнее время от 2 суток. Фольгированных Шариков от 4-5 суток. </p>
						</div>
					</div>

					<div class="pros1 p4">
						<p><i class="fa-solid fa-hourglass-start"></i></p>
						<div>
						<h3>Опыт</h3>
						<p>Благодаря огромному опыту работы с шарами , создав свой курс по обучению, можем с уверенностью гарантировать вашу радость и восторг от выполненной нами работы.</p>
						</div>
					</div>
				</div>
			</div>
  </main>
</template>

<script>
export default {
  
}
</script>

<style>
/*The main part. The Banner*/
.banner {
	background: url('../assets/new_phone.png');
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center right;
	max-width: 100%;
	height: 80vh;
	position: relative;
}

.banner img {
	max-width: 100%;
	height: auto;
}

.info {
	position: absolute;
	top: 40%;
	left: 10%;
}

.info h1 {
	font-size: 62px;
	text-transform: uppercase;
}

.info h3 {
	font-size: 42px;
}

.sale10 {
	text-decoration: none;
	font-size: 1.5em;
	margin: 20px 0px;
	display: inline-block;
	color: black;
	background: lightpink;
	padding: 15px;
	border-radius: 30px;
	transition: 0.5s;
}

.sale10:hover {
	box-shadow: 5px 5px 15px grey;
	text-shadow: 5px 5px 15px white;
}

/*Special Offers. Pictures*/
.sale_container {
	border: 2px solid black;
	box-shadow: 0px 0px 5px dimgrey;
	width: 80%;
	margin: 50px auto 50px auto;
	border-radius: 20px;
	background: whitesmoke;
}

.sale_title {
	text-align: center;
	font-size: 36px;
	text-shadow: 5px 5px 10px lightpink;
	color: black;
	margin-top: 50px;
	text-transform: uppercase;
}

.card_container {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-wrap: wrap;
}

.card {
	height: 500px;
	width: 350px;
	margin: 50px;
	box-shadow: 5px 5px 20px black;
	overflow: hidden;
	position: relative;
	border-radius: 15px;
}

.card img {
	height: 500px;
	width: 350px;
	transition: 0.5s;

}

.cardinfo {
	height: 50px;
	width: 350px;
	padding: 5p;
	background: transparent;
	position: absolute;	
	bottom: 0px;
	color: white;
	transition: 0.5s;
}

.cardinfo h2 {
	font-size: 32px;
	text-align: center;
	margin: 5px;

}

.cardinfo p {
	font-size: 24px;
	text-align: center;
	margin: 5px;
}

.card:hover .cardinfo {
	height: 270px;
	background: rgba(114, 121, 121, 0.7);
}

.card:hover img {
	transform: scale(1.1) rotate(5deg);
}

.sale {
	color: black;
	font-size: 1.4em;
	font-weight: bold;
	padding-left: 30px;
	padding-right: 30px;
	padding-top: 5px;
	padding-bottom: 5px;
	position: absolute;
	top: 0px;
	right: 0px;
	background: rgba(255, 182, 193, .7);
	text-shadow: 0px 0px 5px white;
	border-radius: 20px;
}


/*Special offers. Videos*/
.gif_container {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-wrap: wrap;
}

.gifka {
	margin: 50px;
	position: relative;
	overflow: hidden;
	width: 350px;
	height: 500px;
	box-shadow: 5px 5px 20px black;
	border-radius: 15px;
}

.gifka video {
	width: 350px;
	height: 500px;
	box-shadow: 5px 5px 20px black;
	border-radius: 15px;
	object-fit: cover;
}

.gifka_info {
	position: absolute;
	bottom: 10%;
	left: 50%;
	transform: translateX(-50%);
	hyphens: auto;
	color: white;
}

.first_price {
	text-decoration: line-through;
}

.gifka_info h2 {
	font-size: 2.5rem;
}

.gifka_info h2:first-child {
	text-decoration: line-through;
}


/*Our advances*/
.advance {
	height: 500px;
	background-color: rgba(255, 182, 193, .7);
	width: 80%;
	margin: 0px 10%;
	padding: 5%;
	border-radius: 15px;
}

.advance h2 {
	margin-bottom: 5%;
	text-align: center;
}

.pros {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-row-gap: 20px;
}

.pros1 {
	/*background: lightpink;*/
	display: grid;
	grid-template-columns: 1fr 4fr;
}

.pros img {
	height: 50px;
	width: 50px;
	display: block;
	margin: auto;
}

.pros1 p:first-child {
	display: block;
	margin: auto;
	font-size: 32px;
}

footer {
	margin-top: 50px;
}

.social {
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 30px 0px;
}
</style>

