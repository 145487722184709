<template>
	<article>
			<transition name="modal_in"><div class="modal_mask" v-if="popupWindows"></div></transition>
			<div class="catalog_container">
				<div class="items_container">
					<div class="catalog_item" >
						<div class="watermark">@myballoons.by</div>
						<img :src="item.imageURL">
					</div>
				</div>
				<div>
					<h2>Название: {{ item.title }}</h2>
					<h2>Категория: {{ category.name }}</h2>
					<br>
					<h3>Цена данного набора {{ item.price }}р.00к.</h3>
					<h3>{{ item.description }}</h3>
					<br>
					<h3>Все воздушные шары наполнены гелием.<br>
						Все латексные шары обработаны специальным составом для продления полёта воздушных шаров.<br>
						Состав и цвет заказа можно при необходимости откорректировать: <br>
						изменить цвет шариков или номер цифры.</h3>
						<br>
						<button @click="clickforTest(this.item)" ref="button" class="btn" :disabled="buttonEnabled">В КОРЗИНУ <span><fa icon="dolly" /></span></button>
						<!-- <button @click="popupWindows=true">МОДАЛЬНОЕ ОКНО</button> -->
				</div>
			</div>
			<teleport to="#modal">
				<transition name="modalfade"><div class="modal" v-if="popupWindows">
					<div>
						<h3>Вы добавили в корзину {{ item.title }} <br> из категории {{ category.name }}</h3>
						<button @click="popupWindows=false"><fa class="btn_modal" icon="circle-check" /></button>
					</div>
				</div>
				</transition>
			</teleport>
	</article>
</template>

<script>
import store from "../storage/index"

export default {
    data() {
        return {
            user: "Sam",
            userTitle: this.$route.params.title,
            userCategory: this.$route.params.altName,
			buttonEnabled: false,
			popupWindows: false,
			order: null
        }
    },
	computed: {
		item(){
            let myCategory = this.$store.state.categoriesList.find(category => category.altName === this.$route.params.altName);
            return myCategory.items.find(item => item.title === this.$route.params.title)
		},
		category(){
			return this.$store.state.categoriesList.find(category => category.altName === this.$route.params.altName)
		}
	},
	created() {
		store.commit("getCategories")
        console.log(this.$route.params.altName)
	},
	methods: {
		clickforTest(item) {
			// store.commit("addToCartM", this.item)
			this.buttonEnabled = !this.buttonEnabled
			this.$refs.button.innerHTML = 'ДОБАВЛЕНО' + ' ' + '<i class="fa-solid fa-circle-check"></i>'
			this.popupWindows = true

			let orderItem = new Object()
			orderItem.title = this.item.title
			orderItem.category = this.category.altName
			orderItem.categoryName = this.category.name
			orderItem.imageURL = this.item.imageURL
			orderItem.price = this.item.price
			console.log(orderItem)

			store.commit("addToCartM", orderItem)
		}
	}
}
</script>

<style scoped>
.modalfade-enter-active, .modalfade-leave-active {
  transition: opacity .5s;
}
.modalfade-enter-from, .modalfade-leave-to /* .fade-leave-active до версии 2.1.8 */ {
  opacity: 0;
}
.btn_modal {
	font-size: 3.5em;
	color: black;
	cursor: pointer;
	transition: 0.8s;
}
.btn_modal:hover{
	color: green;
}
.modal_mask{
	background: black;
	opacity: .5;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 100;
}
.modal {
	position: fixed;
	background: white;
	width: 500px;
	height: 300px;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	margin: auto;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 20px;
	text-align: center;
	font-size: 1.2em;
	z-index: 100;
}
.modal div button {
	background: none;
	border: none;
	padding: 0;
}
 .catalog_container {
	/*height: 120vh;*/
	background: linear-gradient(to right, rgba(248, 248, 255, .5), rgba(255, 182, 193, .5));
	margin-top: 50px;
	padding: 3%;
	display: flex;
}

.catalog_container h1 {
	text-align: center;
	font-size: 40px;
	margin-bottom: 50px;
}

.items_container {
	padding-left: 5%;
	padding-right: 5%;
	gap: 2em;
}

.catalog_item {
	background-color: white;
	color: black;
	border-radius: 15px;
	box-shadow: 0px 0px 5px black;
	height: 500px;
	cursor: pointer;
	text-decoration: none;
	overflow: hidden;
	position: relative;
}

.catalog_item img {
	height: 100%;
	width: 100%;
	box-shadow: 0px 0px 5px lightpink;
	box-shadow: 0px 0px 6px darkgrey;
	transition: 1.0s;
}

.catalog_item:hover img {
	transform: scale(1.1);
}
.btn {
	font-size: 1.3em;
	padding: 15px;
	border-radius: 30px;
	border: none;
	appearance: none;
	background-color: black;
	color: white;
	transition: 0.6s;
}
.btn:disabled {
	background: gray;
	opacity: 0.5;
}
.btn:enabled:hover {
	cursor: pointer;
	background: white;
	color: black;
	border: solid 1px black;
}
.watermark {
	position: absolute;
	z-index: 999;
	top: 230px;
	opacity: 0.5;
}
</style>
