<template>
		<article>
			<div class="catalog_container">
				<h1>Композиции {{ category.name }}</h1>
				<div class="items_container">

					
					<div v-for="item in category.items" :key="item.title">
						<router-link class="catalog_item" :to="'/catalog/' + category.altName + '/' + item.title">
						<h2>{{ item.title }}</h2>
						<img :src="item.imageURL">
						<h2>{{ item.price }}р.00к.</h2>
						</router-link>
					</div>
					
				</div>
			</div>
	</article>
</template>
<script>
import store from '@/storage'

export default {
	data() {
		return {
			altName: this.$route.params.altName
		}
	},
	computed: {
		category(){
			return this.$store.state.categoriesList.find(category => category.altName === this.$route.params.altName)
		}
	},
	created() {
		store.commit("getCategories")
		
	}
}
</script>


<style scoped>
 .catalog_container {
	/*height: 120vh;*/
	/* background-color: ghostwhite; */
	background: linear-gradient(to right, rgba(248, 248, 255, .5), rgba(255, 182, 193, .5));
	margin-top: 50px;
	padding: 3%;
}

.catalog_container h1 {
	text-align: center;
	font-size: 40px;
	margin-bottom: 50px;
}

.items_container {
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(100px, 300px));
	justify-content: center;
	align-items: center;
	padding-left: 10%;
	padding-right: 10%;
	gap: 2em;
}

.catalog_item {
	background-color: white;
	color: black;
	border-radius: 15px;
	box-shadow: 0px 0px 5px black;
	height: 500px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-around;
	cursor: pointer;
	text-decoration: none;
}

.catalog_item img {
	height: 330px;
	width: 80%;
	box-shadow: 0px 0px 5px lightpink;
	box-shadow: 0px 0px 6px darkgrey;
	transition: 1.0s;
}

.catalog_item:hover img {
	transform: scale(1.1);
}
</style>
