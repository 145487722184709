<template>
<div class="container_cart">
        <h1>ОФОРМЛЕНИЕ ЗАКАЗ</h1>
    <div v-if="this.orderResult">
        <h3><i class="fa-solid fa-circle-check"></i>
            <br>Ваш заказ успешно оформлен.
            <br>Спасибо, что выбрали нас ! :)
            <br>В ближайшее время мы свяжемся с Вами для согласования деталей заказа!
        </h3>
    </div>

    <div v-else>
        <h3><i class="fa-solid fa-triangle-exclamation"></i>
            <br>К сожалению, при оформлении заказ произошла ошибка.
            <br>Попробуйте оформиь заказ позже.
            <br>Либо связаться с нами удобным для Вас <router-link to="/contacts">способом.</router-link>
        </h3>
   </div>

   </div>
</template>

<script>
import axios from "axios"
import router from '@/router'
import store from '@/storage'

export default {
    data() {
        return {
          

        }
    },
    computed: {
        orderResult() {
            return this.$store.state.orderSuccess
        },
    },
    methods: {
        changeSuccess() {
            console.log("changing success")
            
            store.commit("successChange")
        },
        changeFail() {
            console.log("changing fail")
            
            store.commit("successChange")
        }
    }
}
</script>

<style scoped>
 .container_cart {
	background: linear-gradient(to right, rgba(248, 248, 255, .5), rgba(255, 182, 193, .5));
	margin-top: 50px 0;
	padding: 3%;
}

.container_cart h1, h2, h3 {
	text-align: center;
	font-size: 30px;
	margin-bottom: 50px;
}
</style>