<template>
   <article>
			<div class="catalog_container">
				<h1>Каталог</h1>
				<div class="catalog_items">

					<div v-for="category in categories" :key="category._id" :class="category.cssClass">
						<img :src="category.imageURL" :alt="category.altName">
						<h2><router-link :to="'/catalog/' + category.altName">{{category.name}}</router-link></h2>
					</div>

				</div>
			</div>
		</article>
</template>



<script>
import store from '@/storage'

export default {
	computed: 
	{
		categories() {
			return this.$store.state.categoriesList
		}
	},
	mounted() {
		store.commit("getCategories")
	}
	
}
</script>

<style scoped>
  /*The main part. The Banner*/
.catalog_container {
	max-width: 100%;
	width: 80%;
	height: auto;
	background: linear-gradient(to right, rgba(248, 248, 255, .5), rgba(255, 182, 193, .5));
	margin: 50px auto;
	padding-top: 50px;
	padding-bottom: 50px;
	box-shadow: 0px 0px 5px black;
	border-radius: 15px;
}

.catalog_container h1 {
	text-align: center;
	font-size: 2.5em;
	text-transform: uppercase;
}

.catalog_items {
	margin: 50px 5%;
	display: flex;
	justify-content: center;
	align-items: center;
	flex:1 1 100px;
	flex-wrap: wrap;
	padding-top: 1em;
	padding-bottom: 1em;
	padding-left: 1em;
	padding-right: 1em;
	gap: 1em;
}

.item {
	width: calc((100% / 5) - 1em);
	height: 350px;
	border-radius: 15px;
	background: white;
	box-shadow: 0px 0px 5px black;
	transition: 0.8s;
	position: relative;
}

.item6 {
	animation-duration: 7s;
	animation-iteration-count: infinite;
}

.item img {
	width: 100%;
	height: 100%;
	opacity: 0.6;
	border-radius: 15px;
}

.item h2 {
	text-align: center;
	margin: 0px 0px;
	position: absolute;
	width: 80%;
	top: 45%;
	left: 0px;
	right: 0px;
	margin-left: auto;
	margin-right: auto;
	padding-left: 5px;
	padding-right: 5px;
	border: black 3px solid;
	border-radius: 30px;
	transition: 0.8s;
}

.item a {
	color: black;
	display: block;
	transition: 0.8s;
  text-decoration: none;
}

.item:hover {
	background: lightpink;
}

.item1:hover {
	background: lightblue;
}

.item2:hover {
	background: steelblue;
}

.item3:hover {
	background: dimgrey;
}

.item4:hover {
	background: palevioletred;
}

.item5:hover {
	background: peachpuff;
}

.item6:hover {
	animation-name: gender;  
}

@keyframes gender {
  0% {
    background: pink;
  }
  25% {
	background: blue;
  }
  50% {
    background: pink;
  }
  75% {
    background: blue;
  }
  100% {
    background: pink;
  }
}

.item7:hover {
	background: silver;
}

.item8:hover {
	background: dimgrey;
}

.item9:hover {
	background: yellow;
}

.item10:hover {
	background: cornflowerblue;
}

.item:hover h2 {
	border: white 3px solid;
	color: white;
}

.item:hover a {
	color: white;
}
</style>
