<template>
   <article>
			<div class="container">
				<h1>ДОСТАВКА И ОПЛАТА</h1>
                <div class="delivery_container">
                    <fa icon="truck-arrow-right" class="fa_class"/>
                    <h2>Условия доставки</h2>
                    <p>К доставке принимаются заказы по городу Минску. Возможность доставки в дальние районы(Сокол, Сосны) и пригородные населённые пункты уточняется индивидуально.</p>
                    <p>Минимальная сумма заказа для доставки - 45 р. без учёта стоимости доставки. Стоимость доставки - 5 - 13 р. в зависимости от Вашего района.</p>
                    <p>Время доставки может быть выбрано в интервале с 7.30 до 22.00. Доставка заказа до или после указанного интервала обсуждается индивидуально.</p>
                    
                </div>
                <div class="payment_container">
                    <fa icon="money-bills" class="fa_class"/>
                    <h2>Условия оплаты</h2>
                    <p>Заказ может быть оплачен наличными при получении. Также заказ можно оплатить через систему ЕРИП.</p>
                    <p>Также для юридических лиц и индвидуальных предпринимателей есть возможность оплаты по безналичному рассчёту с документами(Акт выполненнных работ и договор).</p>
                </div>
				
			</div>
		</article>
</template>

<script>

</script>

<style>
.delivery_container {
    display: flex;
    align-items: center;
    flex-direction: column;
}
.payment_container {
    display: flex;
    align-items: center;
    flex-direction: column;
}
.fa_class {
    font-size: 2.5em;
}
.container {
	max-width: 100%;
	width: 80%;
	height: auto;
	background: linear-gradient(to right, rgba(248, 248, 255, .5), rgba(255, 182, 193, .5));
	margin: 50px auto;
	padding-top: 50px;
	padding-bottom: 50px;
	box-shadow: 0px 0px 5px black;
	border-radius: 15px;
}

.container h1 {
	text-align: center;
	font-size: 2.5em;
	text-transform: uppercase;
}


</style>