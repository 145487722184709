import { createStore } from "vuex"
import axios from "axios"

const store = createStore({
    state: {
        cartItems: [],
        categoriesList: null,
        orderSuccess: true

    },
    mutations: {
        addToCartM(state, item) {
            state.cartItems.push(item)
        },
        deleteCartItem(state, item) {
            state.cartItems.pop(item)
        },
        clearItems(state) {
            state.cartItems.length = 0;
        },
        changeSuccess(state) {
            state.orderSuccess = true
        },
        changeFail(state) {
            state.orderSuccess = false
        },
        getCategories(state) {
            axios
		    .get("http://localhost:5011/api/posts")
		    .then(data => state.categoriesList = data.data);
        }
    }
})

export default store