<template>
    <div>
       <main>
		<article>
			<div class="study-container">
				<h1>Обучение</h1>
				<h2>Базовый курс аэродизайна</h2>
				<div class="study-content">
					<p class="text-one">Приглашаю тебя на индивидуальное обучение «Базовый курс по аэродизайну».<br>
						Имея большой опыт работы в шародувной сфере, создав свой личный бренд <a href="https://www.instagram.com/myballoons.by/" target="blank">@myballoons.by</a>, с уверенностью поделюсь своими знаниями и лайфхаками.<br>
						На обучении ты получишь все что нужно знать о шарах , гелии и всех нюансах работы. Научишься работать с баллоном, шарами, аксессуарами. И выйдешь с нужной базой знаний и умением делать красивые наборы шаров! Будешь понимать, что тебе нужно закупить для старта работы, без лишних затрат!<br>
					</p>
					<div class="video-container-one video-container">
						<video muted autoplay loop>
							<source src="/IMG_5601.MP4" type="video/mp4">
						</video>
					</div>
					<div class="video-container-two video-container">
						<video muted autoplay loop>
							<source src="/IMG_5602.MP4" type="video/mp4">
						</video>
					</div>
					<p class="text-two">
						От меня ты получишь:<br>
						-теорию, расскажу и покажу все про шары, гелий, аксессуары<br>
						-практика, а это самое важное<br>
						-рекомендации (список) стартовой закупки<br>
						-база поставщиков<br>
						-мои личные лайфхаки<br>
						-на обучение время и внимание только тебе<br>
						-моя поддержка первые 2 месяца<br>
					</p>
				</div>
					<h2>Подробное описание в <a href=""><i class="fa-brands fa-instagram"></i></a><br>
						Записаться на обучение в <a href=""><i class="fa-brands fa-instagram"></i></a> или <a href="tel:+375259121757">+37525121757</a></h2>
					
					<div class="sertificate">
						<h2>Наш сертификат</h2>
						<img src="../assets/study/sertificate.png" alt="our_sertificate">
					</div>
				</div>
		</article>
	</main>
    </div>
</template>

<style>
.study-container {
	background: linear-gradient(to right, rgba(248, 248, 255, .5), rgba(255, 182, 193, .5));
	height: auto;
	padding: 5%;
	width: 80%;
	margin: 0 auto;
	border-radius: 30px;
	box-shadow: 0px 0px 5px black;
}

.study-container h1 {
	text-transform: uppercase;
	text-align: center;
	font-size: 42px;
}

.study-container h2 {
	text-align: center;
}

.study-content {
	/*display: flex;*/
	display: grid;
	grid-template-areas: 
	"uno uno uno dos"
	"one two two two";
	grid-auto-columns: 3fr 1fr;
	grid-auto-rows: auto;
	align-items: center;
	justify-content: space-around;
	gap: 30px;
	margin: 50px auto 50px auto;
}

.study-content p {
	hyphens: auto;
	font-size: 1.2em;
}

.video-container-one {
	text-align: center;
	grid-area: dos;
}

.video-container-two {
	text-align: center;
	grid-area: one;
}

.text-one {
	grid-area: uno;
}

.text-two {
	grid-area: two;
}

.video-container video {
	border-radius: 15px;
	width: 232px;
	height: 424px;
	z-index: 1;
	box-shadow: 0px 0px 5px black;
}

.sertificate {
	text-align: center;
	margin-top: 50px;
	pointer-events: none;
}

.sertificate img {
	width: 50%;
}
</style>