import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import Catalog from '../views/Catalog.vue'
import Study from '../views/Study.vue'
import Delivery from '../views/Delivery.vue'
import Contacts from '../views/Contacts.vue'
import Category from '../views/Category.vue'
import ItemDetails from '../views/ItemDetails.vue'
import Cart from '../views/Cart.vue'
import Result from '../views/Result.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/catalog',
    name: 'catalog',
    component: Catalog
  },
  {
    path: '/study',
    name: 'study',
    component: Study
  },
  {
    path: '/delivery',
    name: 'delivery',
    component: Delivery
  },
  {
    path: '/contacts',
    name: 'contacts',
    component: Contacts
  },
  {
    path: '/cart',
    name: 'cart',
    component: Cart
  },
  {
    path: '/result',
    name: 'result',
    component: Result
  },
  {
    path: '/catalog/:altName',
    name: 'category',
    component: Category
  },
  {
    path: '/catalog/:altName/:title',
    name: 'details',
    component: ItemDetails
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
