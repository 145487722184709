<template>
<div class="container_cart">
        <h1>КОРЗИНА</h1>
    <div><h2 v-if="this.cartList.length === 0">На данный момент в Вашей корзине нет наборов.
        <br>
        Но Вы можете <router-link to="catalog">что-то выбрать.</router-link></h2>
    </div>
    <div v-if="this.cartList.length > 0">
        <!-- The title of items in the cart -->
        <div class="cartholder cartholder_title" >
        <div>№</div>
        <div>Фото набора</div>
        <div>Название набора</div>
        <div>Категория</div>
        <div>Цена</div>
        <div>Удалить</div>
        </div>
        <!-- Personal item's information -->
    <div class="cartholder"  v-for="(cartitem, index) in cartList" :key="cartitem.item">
        <div>{{ index + 1 }}.</div>
        <div><img :src="cartitem.imageURL" :alt="cartitem.title"></div>
        <div> <router-link :to="'/catalog/' + cartitem.category + '/' + cartitem.title">{{ cartitem.title }} </router-link></div>
        <div> {{ cartitem.categoryName }} </div>
        <div>{{ cartitem.price }}р.00к.</div>
        <div><button @click="deleteItem(cartitem)"><fa icon="trash" /></button></div>
    </div>
    <br>
    <!-- The block for order's summary -->
    <div class="sumary">
        <h3>Итоговая сумма: {{ this.summary }}р.00 к.</h3>
    </div>
   </div>
   <!-- The button to input order's info -->
    <br>
    <div class="btnOrder" v-if="cartList.length > 0" ><button @click="orderOpener=!orderOpener">Оформить заказ</button></div>

    <br>
    <!-- An order's information -->
   <div class="orderInfo" v-if="orderOpener">
   <br>

    <h3>Информация о заказе</h3>
    <h4>Выберите способ доставки</h4>
    <div class="pickupOrDelivery">
    <div >
   <label for="delivery">Доставка </label>
   <input type="radio" id="devivery" name="isDelivery" v-model="isDelivery" v-bind:value="true" class="radiobtn">
   </div>
   <div>
   <label for="takeAway">Самовывоз </label>
   <input type="radio" id="takeAway" name="isDelivery" v-model="isDelivery" v-bind:value="false" class="radiobtn">
   </div>
   </div>

    <!-- The block for delivery -->
    <div class="userContact" v-if="isDelivery">
    <h4>Доставка</h4>
    Для оформления заказа на доставку заполните все необходимые поля.
    <br>После подтверждения и согласования заказ будет доставлен в установленную дату и время.
    <br><span style="color: red">*</span> - обязательное поле
    <br>
    <form> <br><label for="phone">Телефон(В формате +37529ХХХХХХХ)<span style="color: red">*</span>  </label>
    <input type="tel" id="phone" placeholder="Введите номер телефона" v-model="userPhone">
    <span v-if="phoneError" style="color: red">{{ phoneError }}</span>
    <br><label for="userMail">Адрес электронной почты  </label>
    <input type="mail" id="userMail" placeholder="Введите адрес эл.почты" v-model="userMail">
    <br><label for="userAddress">Полный адрес<span style="color: red">*</span>  </label>
    <input type="text" id="userAddress" placeholder="Введите адрес доставки" v-model="userAddress">
    <span v-if="addressError" style="color: red">{{ addressError }}</span>
    <br><label for="userDatum">Дата мероприятия<span style="color: red">*</span>  </label>
    <input type="date" id="userDatum" v-model="userDatum">
    <span v-if="datumError" style="color: red">{{ datumError }}</span>
    <br><label for="userTime">Время доставки  </label>
    <input type="text" id="userTime" v-model="userTime"> 
    <br>
    <label for="userComment">Комментарий к заказу  </label>
    <br><textarea id="userComment" cols="50" rows="5" v-model="userComment"></textarea>
    <br>
    <br>
    </form>
    <div class="btnSend"><button @click="testMethod">Подтвердить заказ</button></div>
    </div>

    <!-- The block for self pick up -->
    <div class="userContact" v-else>
    <h4>Самовывоз</h4>
    Вы можете забрать Ваш заказ самостоятельно по адресу ул. Асаналиева, д.58
    <br>Заполните краткую форму для самовывоза. После подтверждения и согласования заказ можно забрать в установленную дату и время.
    <br><span style="color: red">*</span> - обязательное поле
    <br>
    <form>
    <br><label for="phone">Телефон(В формате +37529ХХХХХХХ)<span style="color: red">*</span>  </label>
    <input type="tel" id="phone" placeholder="Введите номер телефона" v-model="userPhone" required>
    <span v-if="phoneError" style="color: red">{{ phoneError }}</span>
    <br><label for="userMail">Адрес электронной почты  </label>
    <input type="mail" id="userMail" placeholder="Введите адрес эл.почты" v-model="userMail">   
    <br><label for="userDatum">Дата мероприятия<span style="color: red">*</span>  </label>
    <input type="date" id="userDatum" v-model="userDatum">
    <span v-if="datumError" style="color: red">{{ datumError }}</span>
    <br><label for="userTime">Время самовывоза  </label>
    <input type="text" id="userTime" v-model="userTime"> 
    <br>
    <label for="userComment">Комментарий к заказу  </label>
    <br><textarea id="userComment" cols="50" rows="5" v-model="userComment"></textarea>
    <br>
    <br>
    </form>
    <div class="btnSend"><button @click="testMethod">Подтвердить заказ</button></div>
    </div>
   </div>
</div>
</template>

<script>
import store  from "../storage/index"
import axios from "axios"
import router from "@/router"

export default {
    data() {
        return {
           userPhone: "",
           userAddress: "",
           userDatum: "",
           userMail: "",
           userTime: "",
           userComment: "",
           orderOpener: false,
           isDelivery: true,
           phoneError: "",
           addressError: "",
           datumError: "",
           generalError: ""

        }
    },
    computed: {
        counter() {
            return this.$store.state.count
        },
        cartList() {
            return this.$store.state.cartItems
        },
        summary() {
            return this.cartList.reduce((sum, current) => sum + current.price, 0);
        }
    },
    methods: {
        subData() {
            axios.post("http://localhost:5011/api/posts", {
                name: this.cartList[0].name,
                age: this.cartList[0].age,
                user: this.userName,
                phone: this.userNum
            })
        },
        deleteItem(cartitem) {
            console.log(cartitem)
            
            store.commit("deleteCartItem", cartitem)
        },
        testMethod() {

                //Fields for delivery

                if (this.isDelivery) {
                this.phoneError = !this.userPhone ? "Укажите  номер телефона" : ""
                this.addressError = !this.userAddress ? "Укажите полный адрес" : ""
                this.datumError = !this.userDatum ? "Укажите дату мероприятия" : ""

                    if (this.phoneError || this.addressError || this.datumError) {
                        console.log("ошибка во введении данных")
                    }
                
                    else {

                    console.log("Всё ок с данными : )")

                    const userArray = []

                    this.cartList.forEach(element => {
                    userArray.push({name: element.title, category: element.category, price: element.price})
                    });

                    console.log(userArray)

                    const sendData = async() => {

                    try {
                    let res = await axios.post("http://localhost:5011/api/posts", {
                        order: userArray,
                        phone: this.userPhone,
                        email: this.userMail,
                        address: this.userAddress,
                        date: this.userDatum,
                        time: this.userTime,
                        comment: this.userComment,
                        isDelivery: this.isDelivery
                    })
                    }

                    catch (error) {
                        console.log("the is some error in this code")
                        console.log(error)
                        store.commit("changeFail")
                    }

                    finally {
                        console.log("block finally")
                        setTimeout(() => {
                        router.push('/result')
                        store.commit("clearItems")
                        }, 2000);

                        setTimeout(() => {
                            router.push('/')
                        }, 15000);
                    }
                    }

                    sendData();

                    
                }
                
                //Fields for self-pick up

                } else {
                    
                    this.phoneError = !this.userPhone ? "Укажите  номер телефона" : ""
                    this.datumError = !this.userDatum ? "Укажите дату мероприятия" : ""

                    if (this.phoneError || this.datumError) {
                    console.log("ошибка во введении данных")
                    }
                
                    else {

                    console.log("Всё ок с данными : )")

                    const userArray = []

                    this.cartList.forEach(element => {
                    userArray.push({name: element.title, category: element.category, price: element.price})
                    });

                    console.log(userArray)

                    const sendData = async() => {

                    try {
                    let res = await axios.post("http://localhost:5011/api/posts", {
                        order: userArray,
                        phone: this.userPhone,
                        email: this.userMail,
                        date: this.userDatum,
                        time: this.userTime,
                        comment: this.userComment,
                        isDelivery: this.isDelivery
                    })
                    }
                    
                    catch (error) {
                        console.log("the is some error in this code")
                        console.log(error)
                        store.commit("changeFail")
                    }

                    finally {
                        console.log("block finally")
                        setTimeout(() => {
                        router.push('/result')
                        store.commit("clearItems")
                        }, 2000);

                        setTimeout(() => {
                            router.push('/')
                        }, 15000);
                    }
                    }

                    sendData();

                    }
                    
                }  

        }
    }
}
</script>

<style scoped>
 .container_cart {
	background: linear-gradient(to right, rgba(248, 248, 255, .5), rgba(255, 182, 193, .5));
	margin-top: 50px 0;
	padding: 3%;
}

.container_cart h1, h2 {
	text-align: center;
	font-size: 40px;
	margin-bottom: 50px;
}
.cartholder {
    width: 65%;
    margin-left: auto;
    margin-right: auto;
    background: whitesmoke;
    border-radius: 15px;
    padding: 20px;
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    text-align: center;
    align-items: center;
    font-weight: bold;
    font-size: 1.2em;
    border: 1px black solid;
}
.cartholder_title {
    color: white;
    background: dimgray;
}
.cartholder div img {
    width: 100px;
    height: 150px;
}
.cartholder div button {
    font-size: 2em;
    appearance: none;
    border: none;
    background: whitesmoke;
    transition: 0.8s;
}
.cartholder div button:hover {
    transform: scale(1.1);
    cursor: pointer;
    opacity: .8;
}
.cartholder a {
    text-decoration: none;
    color: black;
    transition: 0.8s;
}
.cartholder a:hover {
    color: lightskyblue;
    cursor: pointer;
}
.sumary {
    text-align: right;
    margin-right: 17.5%;
}
.btnOrder {
    text-align: right;
    margin-right: 17.5%;
}
.btnOrder button {
    appearance: none;
    border: none;
    font-size: 1.2em;
    color: white;
    background: dimgrey;
    padding: 10px;
    border-radius: 30px;
    transition: 0.8s;
    cursor: pointer;
}
.btnOrder button:hover {
    opacity: .8;
}
.orderInfo {
    width: 65%;
    margin-left: auto;
    margin-right: auto;
    background: whitesmoke;
    border-radius: 15px;
    border: 1px solid black;
    padding: 15px;
    text-align: center;
}
.orderInfo h3, h4 {
    text-align: center;
}
.radiobtn {
    appearance: none;
    width: 1.2em;
    height: 1.2em;
    background: lightgray;
    border: 3px solid #FFF;
    box-shadow: 0 0 0 1px black;
    border-radius: 50%;
    transition: 0.1s ease;
}
.radiobtn:hover {
    border-width: 0px;
}
.radiobtn:checked {
    box-shadow: 0 0 0 1px black;
    background-color: black;
    border-width: 0.2rem;
}
.userContact {
    text-align: center;
    line-height: 1.8em;
}
.pickupOrDelivery {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 1.2em;
    
}
.pickupOrDelivery div {
    margin-left: 30px;
    margin-right: 30px;
}
.btnSend {
    text-align: center;
}
.btnSend button {
    appearance: none;
    border: none;
    font-size: 1.2em;
    color: white;
    background: dimgrey;
    padding: 10px;
    border-radius: 30px;
    transition: 0.8s;
    cursor: pointer;
}
.btnSend button:hover {
    opacity: .8;
    background: black;
}
</style>